.wrapperAboutMe {
    display: flex;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-content: center;
    position: relative;
}

.profileImg {
    height: 100%;
    position: absolute;
    right: 0%;
}

.wrapperBio {
    background-color: rgba(121, 121, 121, 0.096);
    width: 50%;
    position: absolute;
    height: 80vh; 
    overflow: auto; 
    overflow-x: hidden;
    padding-top: 4vh;
    left: 0%;
    z-index: 2;
}

.bioMoreko {
    font-size: .75rem;
    padding-left: 22%;
    padding-right: 6%;
    padding-top: 2vh;
    line-height: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
}

@media(max-width: 960px) {
    .wrapperBio {
        width: 100%;
        overflow: unset;
        overflow-x: unset;
        padding-top: 3vh;
        display: flex;
        flex-direction: column;
    }

    .bioMoreko {
        font-size: 1rem;
        line-height: 2;
        color: rgb(208, 207, 207);
        padding-top: 2vh;
        padding-bottom: 2vh;
        background-color: #0000009e;
    }
}