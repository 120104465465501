@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@font-face {
  font-family: 'Leixo';
  src: local('Leixo'), url(./assets/LEIXO-DEMO.ttf) format('truetype');
}

*{
  font-family: Leixo;
  font-size: 1.5rem;
  color: rgb(192, 192, 192);
}

.App{
  background-color: rgb(0, 0, 0);
  background-image: url("../src/assets/bkA.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}

p{
  line-height: 1.3rem;
}

.aboutMeBack{
  background-image: url("../src/assets/PagWebBio.jpg");
  background-size: 100vw;
}

.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-size: 100%;
}

.opcion--section{
  display: flex;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
}

.unboton{
  z-index: 10;
}

.heightAllSections{
  margin-top: 2vh;
  min-height: 70vh;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #331b45;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(152, 152, 152,0.3);
  transition: 1.9s all;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(230, 176, 28, 0.7);
}

@media(max-width: 960px){
  .App{
    overflow: auto;
  }
  .main{
    margin-top: 14vh;
    min-height: 72vh;
    overflow: auto;
    overflow-x: hidden;
  }
  .aboutMeBack{
    background-size: auto 100vh;
    background-position: 89%;
  }
}