@import '../../Theme/_variable.scss';

.changeLang{
    padding: .7vh;
    cursor: pointer;
    font-size: .5rem;
    opacity: .7;
    transition: 1s all;
    color: #ffffffd2;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: .7vh;
    border:  $primary-color 2px solid;
    background-color:$back;
    transition: 1s all;
    color: $forth-color;
    border-radius: 8px;&:hover{
        opacity: .9;
        border:  $forth-color 2px solid;
    }
}


@media(max-width: 960px){

    .changeLang{
        font-size: .6rem;
        margin-right: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}