@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: rgb(216, 216, 216);
  font-family: 'Signika', sans-serif;
}

html {
  font-size: calc(12px + (24 - 12) * ((100vw - 200px) / (1920 - 200)));
}

@media(max-width: 960px){
  html {
    font-size: calc(18px + (24 - 18) * ((100vw - 200px) / (960 - 200)));
  }
}

