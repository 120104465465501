@import '../../Theme/_variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');


.mix-master-wrapper--works{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 7vh;
    width: 60%;
    margin-top: 9vh;
    height: 72vh;
    overflow: auto; 
    overflow-x: hidden;
}

.mix-master-work{
    display: flex; 
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    border: 2px solid rgb(196, 162, 14);
    width: 90%;
    padding: 1vh;
    opacity: .8;
    transition: .3s all;
    &:hover{
        opacity: 1;
    }
}


.mix-master-circle--img{
    border: 2px solid rgb(196, 162, 14);
    width: auto;
    display: flex;
    justify-content: center;
    height: 24vh;
    align-items: center;
    transition: 1s all;
    cursor: pointer;&:hover{
        border: 2px dashed rgb(67, 112, 133);
    }
}

.you{
    height: auto;
}
.spoty{
    height: 11vh;
}


.mix-master-text{
    display: flex;
    align-items: center;
    text-align: left;
    overflow-wrap: break-word;
    width: 100%;
    font-size: .7rem;
}

.mix-master-number--index{
    align-self: flex-start;
    opacity: 0.6;
    transition: .2s all;
    font-size: 4rem; 
    transition: .4s all;&:hover{
        color: $secondary-color;
    }
}

.mix-master-title--proy{
    font-family: 'Roboto', sans-serif;
    justify-content: flex-start;
    text-overflow: ellipsis;
    color: $primary-color;
    font-size: 1rem;
    transition: .4s all;&:hover{
        opacity: 1 ;
    }
}

.mix-master-title--year{
    color: $primary-color;
    margin-right: 5%;
    margin-left: auto;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    transition: .4s all;&:hover{
        color: $secondary-color;; 
    }
}

.mix-master-title--desc{
    color: $forth-color;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin-top: 2%;
    font-size: .7rem;
}

.mix-master-section--column{
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    width: 100%;
}

@media(max-width: 960px){
    .mix-master-wrapper--works{
        width: 100%;
        padding-right: 0vh;
        margin-top: 2vh;
        overflow: unset; 
        overflow-x: unset;
    }
    .mix-master-work{
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2vh;
    }
    .mix-master-text{
        text-align: center;
        margin-top: 2vh;
        flex-direction: column;
        font-size: 1rem;
    }
    .mix-master-wrapper--works{
        min-height: 75vh;
    }
    .mix-master-number--index{
        order:-1;
        align-self: center;
    }
    .mix-master-work{
        opacity: 1;
    }
    .mix-master-section--column{
        padding-left: 0%;
    }
    .mix-master-text{
        justify-content: center;
    }
    .mix-master-title--year{
        margin-left: 20px;
    }
    .mix-master-title--desc{
        text-align: center;
    }
    .mix-master-title--proy{
        font-size: 1.5rem;
        margin: .4vh;
    }
    .mix-master-circle--img{
        width: auto;
        height: auto;
    }

    .you{
        height: auto;
        width: 90%;
    }
    .spoty{
        width: 90%;
        height: 15vh;
    }

    @media (max-height: 420px) {
        .spoty{
            width: 100%;
            height: 20vh;
        }
      }
    
}
