@import '../../Theme/_variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.wrapper--works{
    display: flex;
    flex-direction: row;
    overflow: auto;
    overflow-x: hidden;
    width: 90%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 80vh; 
    overflow: auto; 
    overflow-x: hidden;
}

.work{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    min-height: 20vh;
}

.circle--img{
    border: 2px solid rgb(196, 162, 14);
    width: 150px;
    transition: 1s all;
    cursor: pointer;&:hover{
        border: 2px dashed rgb(67, 112, 133);
    }
}

.text{
    overflow-wrap: break-word;
    font-size: .5rem;
}

.number--index{
    align-self: flex-start;
    opacity: 0.6;
    transition: .2s all;
    font-size: 4rem; 
    transition: .4s all;&:hover{
        color: $secondary-color;;
    }
}

.title--proy{
    font-size: .5rem;
    transition: .4s all;&:hover{
        opacity: .7 ;
    }
}

.title--year{
    color: $primary-color;
    font-family: 'Lato', sans-serif;
    transition: .4s all;&:hover{
        color: $secondary-color;; 
    }
}

.title--desc{
    color: $forth-color;
    font-size: .4rem;
}

.iframeSpoty{
    width:300px;
    height:380px;
    margin: 1vh;
}
@media(max-width: 960px){
    .work{
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2vh;
        min-height: 20vh;
    }
    .text{
        text-align: center;
        margin-top: 2vh;
        font-size: 1rem;
    }
    .wrapper--works{
        height: 76vh;
        align-items: center;
        justify-content: center;
    }
    .number--index{
        order:-1;
        align-self: center;
    }
    .iframeSpoty{
        width: 90%;
        height: 100px;
        margin: 2vh;
    }
}
